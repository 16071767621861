import * as React from "react"
import {graphql, Link} from "gatsby";
import { render } from 'datocms-structured-text-to-html-string';
import styled from 'styled-components';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-share.css';
import lgZoom from 'lightgallery/plugins/zoom';
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgHash from 'lightgallery/plugins/hash';


import StaticPageTemplate from "./../components/staticPageTemplate"

import SEO from './../components/SEO';

const Date = styled.div`
    margin-bottom: 25px;
    color: #B8B6B9;
    font-size: 1.15em;
`

const BackButton = styled.div`
    font-family: "Lato", sans-serif;
    font-size: 1.1em;
    transition: color .4s;
    color: #363537;
    margin: 30px 0px;

    &:hover{
        color: #FF7171 !importnant;
    }
`

const GalleryContainer = styled.div`
    & div{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        max-width: 100%;

        @media (max-width: 1600px){
            grid-template-columns: 1fr 1fr;
        }

        @media (max-width: 1200px){
            grid-template-columns: 1fr;
        }
    }
`

const GalleryImageContainer = styled.a`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`

const GalleryImage = styled.img`
    width: 100%;
    max-width: 600px;
    max-height: 600px;
    padding: 10px;
    transition: transform .4s;
    object-fit: cover;

    &:hover{
        transform: scale(1.05);  
    }
`

const ArticleTemplate = ({data: {article}}) => {
  return (
    <StaticPageTemplate title={article.title}>
        <SEO title={article.title} link={"https://debica-dzieciutracone.pl/artykuly/"+article.slug} ogTitle={article.title}/>


        <Date>{article.date}</Date>
       <div className="DatoCMS" dangerouslySetInnerHTML={{__html: render(article.body.value)}}></div>

        <GalleryContainer>
            <LightGallery
                speed={500}
                plugins={[lgThumbnail, lgZoom, lgHash]}
                galleryId={article.slug}
            >
                {article.gallery.map((item,index) => (
                    <GalleryImageContainer href={item.url} key={index}>
                        <GalleryImage src={item.url} alt={item.alt}/>
                    </GalleryImageContainer>
                ))}
            </LightGallery>
        </GalleryContainer>
        

       <BackButton><Link to="/">&laquo; Powrót do strony główej</Link></BackButton>
        
       {/*
       <HorizontalLine/>

       <Disqus
        config={{
            url: "https://debica-dzieciutracone.pl/artykuly/"+article.slug,
            identifier: article.slug,
            title: article.title
            }}
        /> 
        */}
    </StaticPageTemplate>
  )
}

export default ArticleTemplate

export const query = graphql`
    query fetchArticle($slug: String) {
        article: datoCmsArticle(slug: {eq: $slug}){
            title,
            body{
                value
            },
            slug,
            date,
            gallery {
                url
                alt
            }
        }
    }
`